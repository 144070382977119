<template>
  <div v-if="item">
    <gl-tabs>
      <gl-tab :title="pageTitle" active>
        <DetailScreenComponent
          :action-icons="actionIcons"
          :item="item"
          :go-to-list="goToList"
          :fields-to-include="fieldNames"
          :links="getLinks()"
        />
      </gl-tab>
      <gl-tab title="Package Contributions">
        <div class="contributionsBox">
          <div class="contributionsTable">
            <ContributionsAllPage
              :packageId="packageId"
              :itemsPerPage="pageModel.itemsPerPage"
              :enableMultiSelection="true"
              :packagedContributions="item.contributions"
              :onlyPackagedContributions="true"
              @selectRow="selectRow"
              @changePage="resetSelection"
            >
              <template v-slot:moreActions>
                <b-dropdown
                  id="dropdown-1"
                  text="Bulk actions"
                  class="m-md-2"
                  :disabled="selectedContributions.length < 1"
                >
                  <b-dropdown-item @click="addBulkToDeliveryList"
                    >Add to bulk delivery</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </ContributionsAllPage>
          </div>
          <BulkSelectionPreview :packageId="packageId" />
        </div>
      </gl-tab>
    </gl-tabs>
  </div>
</template>

<script>
import ContributionsAllPage from "@/components/Contributions/All/Page.vue";
import AddFirst from "@/components/Destinations/AddFirst.vue";
import DetailScreenComponent from "@/components/DetailScreenComponent.vue";
import BulkSelectionPreview from "@/components/Contributions/BulkSelectionPreview.vue";
import { ITEMS_CONTRIBUTIONS, ITEMS_PACKAGES } from "@/constants.js";
import streamProfileClient from "@/mixins/streamProfileClient.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import dateUtils from "@/mixins/dateUtils";
import {
  entitlementApiClient,
  organizationApiClient,
  packageApiClient,
  metricsApiClient
} from "@/mixins/apiClients";
import userInfo from "@/mixins/userInfo.js";
import { mapMutations } from "vuex";
import AppConfig from "@/app-config";
import { GlTab, GlTabs } from "@gitlab/ui";

const { SHOW: CONTRIBUTION_SHOW, BULK_DELIVERY } = ITEMS_CONTRIBUTIONS;
const { YOUR, EDIT, ENTITLED } = ITEMS_PACKAGES;

export default {
  components: {
    DetailScreenComponent,
    BulkSelectionPreview,
    ContributionsAllPage,
    GlTab,
    GlTabs
  },
  mixins: [
    wizardGuard,
    packageApiClient,
    entitlementApiClient,
    organizationApiClient,
    streamProfileClient,
    metricsApiClient,
    userInfo,
    dateUtils
  ],

  methods: {
    ...mapMutations("packageBulkContributions", ["addContributions"]),
    resetSelection() {
      this.selectedContributions = [];
    },
    selectRow(selected, row) {
      if (selected) {
        if (!this.selectedContributions.find(item => item.id === row.id)) {
          this.selectedContributions.push(row);
        }
      } else {
        this.selectedContributions = this.selectedContributions.filter(
          item => item.id !== row.id
        );
      }
    },
    addBulkToDeliveryList() {
      this.addContributions({
        packageId: this.packageId,
        contributions: this.selectedContributions
      });
      // TODO unselect items?
    },
    getLinks() {
      if (!this.item.contributions || this.item.contributions.length === 0) {
        return null;
      }
      let namesAndRoutes = [];
      for (let i = 0; i < this.item.contributions.length; i++) {
        const route = `#${CONTRIBUTION_SHOW}/${this.item.contributions[i].id}`;
        namesAndRoutes.push({
          name: this.item.contributions[i].name,
          route: route
        });
      }
      return { name: "Contributions included :", list: namesAndRoutes };
    },
    handleKey(evt) {
      if (evt.code === "Escape") {
        this.goToList();
      }
    },

    goToEdit() {
      this.$router.push(`${EDIT}/${this.item.id}`);
    },
    goToList() {
      if (this.item.organisationId === this.userInfo.orgId) {
        this.$router.push(`${YOUR}`);
      } else {
        this.$router.push(`${ENTITLED}`);
      }
    },
    async fetchData() {
      const id = this.$route.params.id;
      this.packageId = id;
      const packageInfo = await this.packageApiClient.getById(id);
      this.item = packageInfo;
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKey);
  },
  async created() {
    await this.fetchData();
  },

  watch: {
    $route(to) {
      this.fetchData();
      this.selectedTab = to.path;
      const item = this.tabsItems.find(a => a.subPageName === this.selectedTab);
      this.openTab(this.selectedTab, item.subComponent, item.data);
    },
    item: {
      handler() {
        this.isDescFulfilled = this.item.name && this.item.description;
      },
      deep: true
    }
  },
  computed: {
    canBulkDeliver() {
      return AppConfig.instance.config.options.features.bulkDelivery;
    },
    pageTitle() {
      return " Package Details";
    },
    actionIcons() {
      return [
        {
          icon: "pencil",
          title: "Click or press the Space Bar to edit this Contribution",
          label: "Edit",
          id: "edit",
          action: this.goToEdit,
          disabled: false
        }
      ];
    }
  },
  data() {
    return {
      packageId: null,
      pageModel: {
        currentPage: 0,
        itemsPerPage: 10,
        totalPages: 0,
        totalItems: 0
      },
      created: false,
      currentComponent: AddFirst,
      item: {
        contributions: {
          items: [],
          totalItems: 0,
          totalPages: 0,
          page: 0
        }
      },
      invalidData: true,
      selectedContributions: [],
      entitlements: [],
      searchFields: {},
      isDescFulfilled: false,
      fieldNames: ["name", "description", "visibility"],
      BULK_DELIVERY
    };
  }
};
</script>

<style scoped>
.tabs {
  margin-top: 50px;
}

.contributionsBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.contributionsTable {
  padding: 10px;
  margin-left: 2rem;
}

.summaryActionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
}
</style>
