import axios from "axios";
import { Auth } from "aws-amplify";

class HttpClient {
  constructor(
    baseUrl,
    storageProvider = window.localStorage,
    addHeaders = true
  ) {
    this.baseUrl = baseUrl;
    this.addHeaders = addHeaders;
    this.storageProvider = storageProvider;
    this.axios = axios.create({
      baseURL: baseUrl
    });
  }

  async getToken() {
    let idToken = null;
    try {
      idToken = await (await Auth.currentSession()).getIdToken();
    } catch (e) {
      console.error(`No id token available`);
      await Auth.federatedSignIn();
      throw e;
    }
    return (idToken || {}).jwtToken;
  }

  getOverrideToken() {
    return this.storageProvider.getItem("jwtTokenOverride");
  }
  async getHeaders() {
    if (!this.addHeaders) {
      return {};
    }
    const token = this.getOverrideToken() || (await this.getToken());
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return {
      headers,
      withCredentials: true
    };
  }

  async post(path, body, selfValidate = false) {
    const headers = await this.getHeaders();
    if (selfValidate) headers.validateStatus = null;
    return this.axios.post(path, body, headers);
  }

  async get(path) {
    return this.axios.get(path, await this.getHeaders());
  }

  async put(path, body, selfValidate = false) {
    const headers = await this.getHeaders();
    if (selfValidate) headers.validateStatus = null;
    return this.axios.put(path, body, headers);
  }

  async putImage(path, body) {
    const headers = await this.getHeaders();
    headers.headers["accept"] = "image/png";
    return this.axios.put(path, body, headers);
  }

  async delete(path) {
    return this.axios.delete(path, await this.getHeaders());
  }

  async patch(path, body) {
    return this.axios.patch(path, body, await this.getHeaders());
  }
}

export default HttpClient;
